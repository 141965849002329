@use 'sass:color';
@use 'sass:math';
@use '../base/variables' as *;
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

/** Media Queries **/
@mixin telefono {
    @media (min-width: #{$telefono}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet}) {
        @content;
    }
}
  
@mixin desktop {
    @media (min-width: #{$desktop}) {
        @content;
    }
}
@mixin grid($columnas, $espaciado ) {
    display: grid;
    gap: $espaciado;
    grid-template-columns: repeat($columnas, 1fr);
}
@mixin boton($bgColor, $display) {
    background-color: $bgColor;
    color: $blanco;
    font-weight: bold;
    text-decoration: none;
    border-radius: .5rem;
    padding: 1rem 3rem;
    text-align: center;
    margin-top: math.div($separacion, 2);
    display: $display;
    transition: all .2s;
    border: none;
    
    &:hover {
        background-color: color.adjust($bgColor, $lightness: -10%);
        cursor: pointer;
    }
}

.click-btn {
    display: flex;
    width: 70%;
    height: 4rem;
    justify-content: center;
    align-items: center;
    margin: 2rem auto 0 auto;
    line-height: 35px;
    border: 1px solid;
    border-radius: .4rem;
    text-align: center;
    font-size: 16px;
    color: #000;
    text-decoration: none;
    transition: all 0.35s;
    box-sizing: border-box;
}
@mixin boton2($bgColor) {
    position: relative;
    border-color: $bgColor;
    background-color: $bgColor;
    color: $negro;
    overflow: hidden;
        span[data-name="ver"] {
            display: inline-block;
            font-weight: 400;
            transition: all 0.25s;
            transform: translate(0, 0);
        }
        span[data-name="propiedad"] {
            margin-left: 0.25em;
            transition: all 0.25s;
            opacity: 1;
        }
        .block {
            width: 15%;
            height: 100%;
            position: absolute;
            top: 0;
            left: -30%;
            background-color: rgba(0, 112, 144, 0.644);
            border-radius: 0 200% 200% 0;
            transition: all 0.25s;
            z-index: 1;
        span {
            display: block;
            &::before {
                content: "\f054";
                font-family: "FontAwesome";
                }
            }
        }
        &:hover {
            span[data-name="propiedad"] {
                opacity: 0;
            }
            span[data-name="ver"] {
                font-weight: 700;
                transition-delay: 0.2s;
                transform: translate(3em, 0);
                letter-spacing: 2px;
            }
            .block {
                transition-delay: 0.2s;
                left: 0;
            }
        }
}