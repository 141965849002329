@use '../base/fonts' as *;
@use 'sass:color';

// Fuentes
$fuente_principal: 'Lato', sans-serif;

// Tamaño de Media Queries
$telefono: 480px;
$tablet: 1000px;
$desktop: 1200px;

// Colores
$negro: #000000;
$amarillo: #0bdaf1ab;
$celesteOscuro: #003d55;
$verde: #00b0b0;
$blanco: #FFFFFF;
$gris: #e1e1e1;
$grisOscuro: #333333;
$grisClaro: #9d9d9d;
$rojo: rgba(255, 0, 0, 0.662);
$rojoClaro: #ff4800;

// 🎨 Variables de colores accesibles
$turquesa: #009688;
$turquesaClaro: color.adjust($turquesa, $lightness: 15%);
$turquesaOscuro: color.adjust($turquesa, $lightness: -15%);
$gris-claro: #f4f4f4;
$gris-medio: #ccc;
$gris-oscuro: #333;
$texto: #222;

$separacion: 5rem;

// Fuentes
$delgada: 300;
$regular : 400;
$bold: 700;
$black: 900