@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'),
         url('../fonts/Montserrat-Italic-VariableFont.woff2') format('woff2');
    font-weight: 100 900;
    font-style: italic;
    font-display: swap;
    font-variation-settings: 'wght' 100 900;
}

@font-face {
    font-family: 'Roboto Flex';
    src: local('Roboto Flex'),
         url('../fonts/RobotoFlex-VariableFont.woff2') format('woff2');
    font-weight: 100 900;
    font-style: normal;
    font-display: swap;
    font-variation-settings: 'wght' 100 900, 'wdth' 75 125;
}

// Fuente Lato (fuente estática - múltiples archivos)
@font-face {
    font-family: 'Lato';
    src: local('Lato'),
         url('../fonts/Lato-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'),
         url('../fonts/Lato-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'),
         url('../fonts/Lato-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'),
         url('../fonts/Lato-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}