@use '../base/mixins' as *;
@use '../base/variables' as *;
@use '../base/fonts' as *;
@use 'sass:color';

@include tablet {
    .contenedor-anuncios {
        @include grid(3, 2rem);
    }
}

.anuncio {
    position: relative;
    background-color: color.adjust($gris, $lightness: 5%);
    border: 1px solid color.adjust($gris, $lightness: -5%);
    margin-bottom: 2rem;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;

    img {
        height: auto;
    }

    .banda {
        position: absolute;
        top: -15px;
        right: -20px; // Ajusta el espacio desde el borde derecho del anuncio
        background-color: $rojoClaro;
        color: $blanco;
        font-size: 1.4rem;
        font-weight: bold;
        padding: 5px 15px;
        transform: rotate(25deg);
        text-transform: uppercase;
        border-radius: 5px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
        z-index: 20;
        will-change: transform, opacity; // Optimización del renderizado
        pointer-events: none; // Evita que interfiera con el hover

        &.disponible {
            background-color: $verde;
        }
    
        &.vendida {
            background-color: $turquesaOscuro;
        }
    
        &.arrendada {
            background-color: $rojoClaro;
        }
    }

    h3, p {
        margin: 0;
    } 
}

.resumen-propiedad {
    background-color: $gris;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .3) !important;
    .precio {
        font-size: 3rem;
        font-weight: 900;
        text-align: center;
        margin: 0;
        color: $turquesaOscuro;
    }
    .contenedor-contactanos {
        p {
            margin: 0;
        }
        .boton-verde {
            padding: .1rem 1rem;
            margin: 0;
        }
    }
    .iconos-caracteristicas {
        background-color: $gris;
        border-radius: 2rem;
        li {
            p {
                font-size: 2rem;
            }
        }
    }
    #Bano, #Estac, #Dormi {
        width: 5rem;
        height: 5rem;
    }
    #SpanBano, #SpanEstac, #SpanDormi {
        font-size: 1.5rem;
    }
    .contenedor-estado {
        display: flex;
        justify-content: center;
        gap: 1rem;
        h3 {
            margin: 0;
        }
        .banda {
            position: relative;
            color: $blanco;
            font-size: 1.4rem;
            font-weight: bold;
            padding: 1px 15px;
            line-height: 5rem;
            text-transform: uppercase;
            border-radius: 5px;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

            &.disponible {
                background-color: $verde;
            }
        
            &.vendida {
                background-color: $turquesaOscuro;
            }
        
            &.arrendada {
                background-color: $rojoClaro;
            }
        }
    }
}

// 🏠 Estilos del contenedor de la propiedad
.contenido-anuncio {
    padding: 2rem;
    background-color: $gris-claro;
    padding: 1.5rem;
    min-height: 45rem;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    will-change: transform;

    &:hover {
        transform: translateY(-7px);
    }

    .contenedor-titulo_propiedad {
        height: 6rem;
        align-content: center;
        margin-bottom: 1.5rem;
        .titulo-propiedad {
            font-size: 2.5rem;
            color: $turquesaOscuro;
            font-weight: bold;
            line-height: 3rem;
        }
    }
    

    .contenedor-descripcion_propiedad {
        margin-bottom: 1rem;
        align-content: center;
        height: 9rem;

        .descripcion-propiedad {
            font-size: 1.5rem;
            color: $texto;
            line-height: 1.6;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* Limitar a 3 líneas */
            line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 4.5em; /* Ajusta este valor según el tamaño de la línea */
        }
        /* Contenedor para los tres puntos */
        .tres-puntos-container {
            display: flex;
            justify-content: flex-end; /* Alinea los tres puntos a la derecha */
            width: 100%; /* Asegura que ocupe todo el ancho */
        }
        .ver-tres-puntos {
            display: none;
            color: $turquesaOscuro;
            cursor: pointer;
            text-decoration: none;
            font-size: 1.5rem;
            font-weight: bold;
            background-color: $turquesaClaro;
            border-radius: .5rem;
            padding: 0 1rem;
            &:hover {
                background-color: $turquesaOscuro;
                color: color.adjust($turquesaClaro, $lightness: 10%);
            }
        }

        &.con-puntos {
            .ver-tres-puntos {
            display: inline-block; // Mostrar los tres puntos si hay más de 3 líneas
            }
        }
    }

    .precio-container {
        background-color: $turquesa;
        color: white;
        font-size: 1.4rem;
        font-weight: bold;
        padding: 0.8rem;
        border-radius: 5px;
        text-align: center;
        margin-bottom: 1rem;
        .precio {
            color: white;
            text-shadow: 1px 1px 2px $turquesaOscuro;
            font-size: 2.5rem;
            font-weight: 800;
        }
    }

    .iconos-caracteristicas {
        display: flex;
        justify-content: space-around;
        padding: 1rem 0;
        list-style: none;
        margin-bottom: 0;

        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1px;
            font-size: 1rem;
            color: $texto;

            .icono {
                width: 30px;
                height: 33px;
                filter: brightness(0) saturate(100%) invert(30%); // 🔹 Mejora contraste en fondo claro
            }

            p {
                font-weight: bold;
                font-size: 1.5rem;
            }

            span {
                font-size: 1rem;
                color: color.adjust($texto, $lightness: -15%);
            }
        }
    }

    .separador {
        height: 2px;
        background-color: $turquesaOscuro;
        margin: 1rem 0;
    }
}