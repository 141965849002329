@use '../base/variables.scss' as *;
@use 'sass:math';
html {
    font-size: 62.5%;
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}
body {
    font-family: $fuente_principal;
    font-size: 1.6rem;
    line-height: 1.8;
    transition: all .3s;
}
p {
    color: $negro;
    font-size: 2rem;
}
.contenedor {
    width: 95%;
    max-width: 120rem;
    margin: 0 auto;
    h2 {
        font-weight: 800;
    }
}
.contenido-centrado {
    h1 {
        font-weight: 600;
    }
}

a {
    text-decoration: none;
}

img,
picture {
    width: 100%;
    display: block;
}

h1, h2, h3 {
    font-family: $fuente_principal;
    margin: math.div($separacion, 2) 0;
    font-weight: 300;
    text-align: center;
    
}

h1 {
    font-size: 3.8rem;
    font-weight: 600;
}
h2 {
    font-size: 3.4rem;
}
h3 {
    font-size: 3rem;
    font-weight: 500;
}
h4 {
    font-size: 2.6rem;
}