@use '../base/mixins' as *;
@use '../base/variables' as *;

.no-webp .inicio {
    background-image: url(../img/header.png);
}
.webp .inicio {
    background-image: url(../img/header.webp);
}

.header {
    background-color: $grisOscuro;
    padding: 1rem 0 3rem 0;

    &.inicio {
        background-position: center center;
        background-size: cover;
        
        @include tablet {
            min-height: 70rem;
        }

        .contenido-header {
            @include tablet {
                min-height: 45rem;
            }
        }
    }
}

.contenido-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    h1 {
        text-align: center;
        color: $blanco;
        font-weight: $bold;
        line-height: 2;
    }
}