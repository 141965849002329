@use '../base/mixins' as *;
@use '../base/variables' as *;
@use '../base/fonts' as *;
@use 'sass:color';

@include tablet {
    .contenedor-anuncios {
        @include grid(3, 2rem);
    }
}

.anuncio {
    background-color: color.adjust($gris, $lightness: 5%);
    border: 1px solid color.adjust($gris, $lightness: -5%);
    margin-bottom: 2rem;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;

    .contenido-anuncio {
        padding: 2rem;
    }
    h3, p {
        margin: 0;
    }
     
}

.resumen-propiedad {
    background-color: $gris;
    padding: 2rem;
    border-radius: 1rem;
}

// 🏠 Estilos del contenedor de la propiedad
.contenido-anuncio {
    background-color: $gris-claro;
    padding: 1.5rem;
    min-height: 45rem;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: translateY(-7px);
    }

    .contenedor-titulo_propiedad {
        height: 6rem;
        align-content: center;
        margin-bottom: 1.5rem;
        .titulo-propiedad {
            font-size: 2.5rem;
            color: $turquesaOscuro;
            font-weight: bold;
            line-height: 3rem;
        }
    }
    

    .contenedor-descripcion_propiedad {
        margin-bottom: 1rem;
        align-content: center;
        height: 7.2rem;
        .descripcion-propiedad {
            font-size: 1.5rem;
            color: $texto;
            line-height: 1.6;
        }
    }

    .precio-container {
        background-color: $turquesa;
        color: white;
        font-size: 1.4rem;
        font-weight: bold;
        padding: 0.8rem;
        border-radius: 5px;
        text-align: center;
        margin-bottom: 1rem;
        .precio {
            color: white;
            text-shadow: 1px 1px 2px $turquesaOscuro;
            font-size: 2.5rem;
            font-weight: 800;
        }
    }

    .iconos-caracteristicas {
        display: flex;
        justify-content: space-around;
        padding: 1rem 0;
        list-style: none;
        margin-bottom: 0;

        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1px;
            font-size: 1rem;
            color: $texto;

            .icono {
                width: 30px;
                height: 33px;
                filter: brightness(0) saturate(100%) invert(30%); // 🔹 Mejora contraste en fondo claro
            }

            p {
                font-weight: bold;
                font-size: 1.5rem;
            }

            span {
                font-size: 1rem;
                color: color.adjust($texto, $lightness: -15%);
            }
        }
    }

    .separador {
        height: 2px;
        background-color: $turquesaOscuro;
        margin: 1rem 0;
    }
}