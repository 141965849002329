@use '../base/fonts' as *;

// Fuentes
$fuente_principal: 'Lato', sans-serif;

// Tamaño de Media Queries
$telefono: 480px;
$tablet: 1000px;
$desktop: 1200px;

// Colores
$negro: #000000;
$amarillo: #0bdaf1ab;
$verde: #00b0b0;
$blanco: #FFFFFF;
$gris: #e1e1e1;
$grisOscuro: #333333;
$grisClaro: #9d9d9d;
$rojo: rgba(255, 0, 0, 0.662);
$rojoClaro: #ff4800;

$separacion: 5rem;

// Fuentes
$delgada: 300;
$regular : 400;
$bold: 700;
$black: 900