@use '../base/variables' as *;
@use '../base/mixins' as *;
@use 'sass:color';

.alerta {
    padding: .5rem;
    text-align: center;
    color: $blanco;
    text-transform: uppercase;
    margin: 1rem 0;
    border-radius: .5rem;
    transition: all .5s ease-out;
    &.error {
        background-color: $rojo;
    }
    &.exito {
        background-color: $verde;
    }
}

// Tabla Propiedades
table.propiedades {
    margin-top: 3rem;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;

    thead {
        background-color: color.adjust($verde, $lightness: -8%);
        th {
            color: $blanco;
            padding: 1.25rem;
            font-weight: 600;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            font-size: 1rem;
            text-align: center;
            position: relative;
            
            &:not(:last-child):after {
                content: '';
                position: absolute;
                right: 0;
                top: 25%;
                height: 50%;
                width: 1px;
                background-color: rgba($blanco, 0.3);
            }
        }
    }

    .imagen-tabla {
        width: 15rem;
        height: 10rem;
        object-fit: cover;
        margin: 0.5rem auto;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        
        &:hover {
            transform: scale(1.03);
        }
    }

    tbody {
        tr {
            transition: background-color 0.2s ease;
            
            &:nth-child(even) {
                background-color: rgba($verde, 0.03);
            }
            
            &:hover {
                background-color: rgba($verde, 0.08);
            }
            
            td {
                padding: 1.25rem 1rem;
                color: color.adjust($gris, $lightness: -60%);
                font-weight: 500;
                text-align: center;
                border-bottom: 1px solid color.adjust($gris, $lightness: 15%);
                
                &:first-child {
                    font-weight: 500;
                    color: color.adjust($gris, $lightness: -50%);
                }

                .boton-amarillo-block {
                    color: color.adjust($turquesa, $lightness: -13%);
                    &:hover {
                        color: $blanco;
                    }
                }

                .bandaAdmin {
                    position: relative; // Cambiamos a absolute
                    left: 30rem;       // Posición desde la izquierda
                    bottom: -2rem;      // Posición desde abajo
                    opacity: .7;
                    background-color: $rojoClaro;
                    color: $blanco;
                    width: 30%;
                    min-width: 30%;
                    font-size: 1.2rem;
                    font-weight: bold;
                    padding: 5px 15px;
                    text-transform: uppercase;
                    border-radius: 5px;
                    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
                    &.disponible {
                        background-color: $verde;
                        color: $negro;
                    }
                
                    &.vendida {
                        background-color: $turquesaOscuro;
                    }
                
                    &.arrendada {
                        background-color: $rojoClaro;
                    }
                }
            }
            
            &:last-child {
                td {
                    border-bottom: none;
                    gap: 1rem;
                }
            }
        }
    }

    @media (max-width: 768px) {
        thead {
            display: none;
        }
        
        tbody {
            tr {
                display: block;
                margin-bottom: 1.5rem;
                border: 1px solid color.adjust($gris, $lightness: 15%);
                border-radius: 6px;
                padding: 0.5rem;
                
                td {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0.75rem;
                    border-bottom: none;
                    text-align: right;
                    gap: 1rem;
                    
                    &:before {
                        content: attr(data-label);
                        font-weight: 600;
                        color: color.adjust($gris, $lightness: -50%);
                        margin-right: 1rem;
                    }
                    
                    &:first-child {
                        background-color: rgba($verde, 0.05);
                        border-radius: 4px 4px 0 0;
                    }
                    
                    &:last-child {
                        border-radius: 0 0 4px 4px;
                    }
                }
            }
        }
        
        .imagen-tabla {
            width: 100%;
            height: auto;
        }
    }
}

.imagen-small {
    width: 30rem;
}

.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}

.modal-contenido {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}