@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("../fonts/Montserrat-Italic-VariableFont.woff2") format("woff2");
  font-weight: 100 900;
  font-style: italic;
  font-display: swap;
  font-variation-settings: "wght" 100 900;
}
@font-face {
  font-family: "Roboto Flex";
  src: local("Roboto Flex"), url("../fonts/RobotoFlex-VariableFont.woff2") format("woff2");
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
  font-variation-settings: "wght" 100 900, "wdth" 75 125;
}
@font-face {
  font-family: "Lato";
  src: local("Lato"), url("../fonts/Lato-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: local("Lato"), url("../fonts/Lato-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: local("Lato"), url("../fonts/Lato-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: local("Lato"), url("../fonts/Lato-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
/** Media Queries **/
.click-btn {
  display: flex;
  width: 16rem;
  height: 4rem;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  line-height: 35px;
  border: 1px solid;
  border-radius: 0.4rem;
  text-align: center;
  font-size: 16px;
  color: #000;
  text-decoration: none;
  transition: all 0.35s;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: "Lato", sans-serif;
  font-size: 1.6rem;
  line-height: 1.8;
  transition: all 0.3s;
}

p {
  color: #000000;
  font-size: 2rem;
}

.contenedor {
  width: 95%;
  max-width: 120rem;
  margin: 0 auto;
}
.contenedor h2 {
  font-weight: 800;
}

.contenido-centrado h1 {
  font-weight: 600;
}

a {
  text-decoration: none;
}

img,
picture {
  width: 100%;
  display: block;
}

h1, h2, h3 {
  font-family: "Lato", sans-serif;
  margin: 2.5rem 0;
  font-weight: 300;
  text-align: center;
}

h1 {
  font-size: 3.8rem;
}

h2 {
  font-size: 3.4rem;
}

h3 {
  font-size: 3rem;
}

h4 {
  font-size: 2.6rem;
}

.swal2-title, .swal2-html-container {
  font-size: 2.5rem;
}

.swal2-html-container {
  font-size: 1.5rem;
}

.swal2-success-circular-line-left,
.swal2-success-circular-line-right,
.swal2-success-fix {
  background-color: transparent !important;
}

.swal2-success {
  color: #00ff00;
  border-color: #00ff00;
  background-color: transparent;
}

.swal2-confirm {
  background-color: #00ff00;
  color: #1e1e1e;
  border: none;
  font-size: 1.5rem;
}
.swal2-confirm:hover {
  background-color: #00cc00;
}

.imagen-container {
  position: relative;
  display: inline-block;
}
.imagen-container .btn-eliminar-imagen {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff4800;
  transition: box-shadow 0.2s ease-in;
  color: #fff;
  border: 1px solid #333333;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  line-height: 1;
}
.imagen-container .btn-eliminar-imagen:hover {
  box-shadow: 0 0 0.3rem 0.4rem rgba(11, 218, 241, 0.7);
}
.imagen-container:hover .btn-eliminar-imagen {
  display: flex;
}

.seccion {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.contenido-centrado {
  max-width: 80rem;
}

.alinear-derecha {
  display: flex;
  justify-content: flex-end;
}

.w-100 {
  width: 100%;
}

.boton-amarillo {
  background-color: rgba(11, 218, 241, 0.6705882353);
  color: #FFFFFF;
  font-weight: bold;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: inline-block;
  transition: all 0.2s;
  border: none;
}
.boton-amarillo:hover {
  background-color: rgba(8.7738095238, 173.880952381, 192.2261904762, 0.6705882353);
  cursor: pointer;
}

.boton-amarillo-block {
  background-color: rgba(11, 218, 241, 0.6705882353);
  color: #FFFFFF;
  font-weight: bold;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: block;
  transition: all 0.2s;
  border: none;
}
.boton-amarillo-block:hover {
  background-color: rgba(8.7738095238, 173.880952381, 192.2261904762, 0.6705882353);
  cursor: pointer;
}

.boton-turquesa {
  position: relative;
  border-color: rgba(11, 218, 241, 0.6705882353);
  background-color: rgba(11, 218, 241, 0.6705882353);
  color: #fff;
  overflow: hidden;
}
.boton-turquesa span[data-name=ver] {
  display: inline-block;
  transition: all 0.25s;
  transform: translate(0, 0);
}
.boton-turquesa span[data-name=propiedad] {
  margin-left: 0.25em;
  transition: all 0.25s;
  opacity: 1;
}
.boton-turquesa .block {
  width: 30%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -30%;
  border-radius: 0 200% 200% 0;
  transition: all 0.25s;
  z-index: 1;
}
.boton-turquesa .block span {
  display: block;
}
.boton-turquesa .block span::before {
  content: "\f054";
  font-family: "FontAwesome";
}
.boton-turquesa:hover span[data-name=propiedad] {
  opacity: 0;
}
.boton-turquesa:hover span[data-name=ver] {
  transition-delay: 0.2s;
  transform: translate(3em, 0);
  letter-spacing: 2px;
}
.boton-turquesa:hover .block {
  transition-delay: 0.2s;
  left: 0;
}

.boton-verde {
  background-color: #00b0b0;
  color: #FFFFFF;
  font-weight: bold;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: inline-block;
  transition: all 0.2s;
  border: none;
}
.boton-verde:hover {
  background-color: #007d7d;
  cursor: pointer;
}

.boton-rojo-block {
  background-color: rgba(255, 0, 0, 0.662);
  color: #FFFFFF;
  font-weight: bold;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: block;
  transition: all 0.2s;
  border: none;
  width: 100%;
}
.boton-rojo-block:hover {
  background-color: rgba(204, 0, 0, 0.662);
  cursor: pointer;
}

.boton-verde-block {
  background-color: #00b0b0;
  color: #FFFFFF;
  font-weight: bold;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: block;
  transition: all 0.2s;
  border: none;
}
.boton-verde-block:hover {
  background-color: #007d7d;
  cursor: pointer;
}

.dark-mode {
  background-color: rgb(25.5, 25.5, 25.5);
  transition: all 0.3s;
  /* Estilos para el modo oscuro de SweetAlert2 */
}
.dark-mode b {
  color: #FFFFFF;
}
.dark-mode a, .dark-mode h1, .dark-mode h3, .dark-mode h4, .dark-mode p {
  color: #FFFFFF;
}
.dark-mode a, .dark-mode h1, .dark-mode h2, .dark-mode h3, .dark-mode h4 {
  color: #e1e1e1;
}
.dark-mode .anuncio, .dark-mode .resumen-propiedad, .dark-mode .resumen-entrada {
  background-color: #333333;
  border: 1px solid #333333;
}
.dark-mode .anuncio .precio, .dark-mode .resumen-propiedad .precio, .dark-mode .resumen-entrada .precio {
  color: #00b0b0;
}
.dark-mode .anuncio .icono, .dark-mode .resumen-propiedad .icono, .dark-mode .resumen-entrada .icono {
  filter: invert(1);
}
.dark-mode .resumen-entrada, .dark-mode .resumen-propiedad {
  padding: 2rem;
  border-radius: 1rem;
}
.dark-mode .formulario legend, .dark-mode .formulario label, .dark-mode .formulario p {
  color: #e1e1e1;
}
.dark-mode .formulario input:not([type=submit]),
.dark-mode .formulario textarea, .dark-mode .formulario select {
  background-color: #333333;
  border: 1px solid #333333;
  color: #FFFFFF;
}
.dark-mode table.propiedades tbody tr td {
  color: #FFFFFF;
  border-color: #333333;
}
.dark-mode .modal .modal-contenido h2 {
  font-weight: bold;
}
.dark-mode .modal .modal-contenido h2, .dark-mode .modal .modal-contenido p {
  color: #333333;
}
.dark-mode .contenido-centrado h1 {
  font-weight: 600;
}
.dark-mode .swal2-dark {
  background: #1e1e1e;
  color: #ffffff;
}
.dark-mode .swal2-dark .swal2-title, .dark-mode .swal2-dark .swal2-html-container {
  color: #ffffff;
  font-size: 2.5rem;
  background-color: #1e1e1e;
}
.dark-mode .swal2-dark .swal2-html-container {
  color: #cccccc;
  font-size: 1.5rem;
}
.dark-mode .swal2-dark .swal2-success-circular-line-left,
.dark-mode .swal2-dark .swal2-success-circular-line-right,
.dark-mode .swal2-dark .swal2-success-fix {
  background-color: transparent !important;
}
.dark-mode .swal2-dark .swal2-success {
  color: #00ff00;
  border-color: #00ff00;
  background-color: transparent;
}
.dark-mode .swal2-dark .swal2-confirm {
  background-color: #00ff00;
  color: #1e1e1e;
  border: none;
  font-size: 1.5rem;
}
.dark-mode .swal2-dark .swal2-confirm:hover {
  background-color: #00cc00;
}

#whatsapp-button {
  position: fixed;
  bottom: 10rem;
  right: 2rem;
  z-index: 1001;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #25D366;
  cursor: pointer;
  transition: all 0.3s ease;
}
#whatsapp-button a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  background: none;
}
#whatsapp-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#25D366 0 0) no-repeat calc(200% - var(--p, 0%)) 100%/200% var(--p, 0.2em);
  transition: 0.3s var(--t, 0s), background-position 0.3s calc(0.3s - var(--t, 0s));
  z-index: -1;
}
#whatsapp-button:hover {
  --p: 100%;
  --t: 0.3s;
  color: white;
}
#whatsapp-button svg {
  width: 60%;
  height: 60%;
  fill: rgb(9, 184, 9);
  filter: drop-shadow(2px 2px 2px rgb(0, 0, 0));
  transition: transform 0.3s ease-in-out;
  stroke: rgb(9, 184, 9);
  stroke-width: 0.3px;
}
#whatsapp-button svg:hover {
  transform: scale(1.1);
}

swiper-container {
  width: 100%;
  height: 100%;
}

swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

swiper-container {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

swiper-slide {
  background-size: cover;
  background-position: center;
  border-radius: 2rem;
}

.mySwiper {
  height: 80%;
  width: 100%;
  padding: 1rem 3rem;
}

@media (min-width: 1000px) {
  .mySwiper {
    padding: 1rem 30rem;
  }
}
.mySwiper2 {
  height: 20%;
  box-sizing: border-box;
  padding: 1rem 3rem;
}

@media (min-width: 1000px) {
  .mySwiper2 {
    padding: 1rem 30rem;
  }
}
.mySwiper2 swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper2 .swiper-slide-thumb-active {
  opacity: 1;
}

swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2rem;
}

/** UI **/
.no-webp .inicio {
  background-image: url(../img/header.png);
}

.webp .inicio {
  background-image: url(../img/header.webp);
}

.header {
  background-color: #333333;
  padding: 1rem 0 3rem 0;
}
.header.inicio {
  background-position: center center;
  background-size: cover;
}
@media (min-width: 1000px) {
  .header.inicio {
    min-height: 70rem;
  }
}
@media (min-width: 1000px) {
  .header.inicio .contenido-header {
    min-height: 45rem;
  }
}

.contenido-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contenido-header h1 {
  text-align: center;
  color: #FFFFFF;
  font-weight: 700;
  line-height: 2;
}

.footer {
  padding: 2.5rem 0;
  background-color: #333333;
  margin-bottom: 0;
}
.footer .contenedor-footer {
  padding-top: 2.5rem;
}
.footer .navegacion {
  visibility: visible;
  opacity: 1;
  height: auto;
}
.footer .copyright {
  color: #FFFFFF;
  text-align: center;
}

/* ------------------ Estilos Generales de Navegación ----------------- */
.navegacion {
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s linear, height 0.3s linear;
}
@media (min-width: 1000px) {
  .navegacion {
    display: flex;
    align-items: center;
    opacity: 1;
    visibility: visible;
  }
}
.navegacion a:not(.admin, .cerrar-sesion) {
  color: #FFFFFF;
  text-decoration: none;
  font-size: 2.2rem;
  padding-bottom: 0.3rem;
  position: relative;
  display: block;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.navegacion a:not(.admin, .cerrar-sesion):hover {
  color: #00b0b0;
}
.navegacion a:not(.admin, .cerrar-sesion)::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #00b0b0;
  transition: width 0.3s ease-in-out;
}
.navegacion a:not(.admin, .cerrar-sesion):hover::after {
  width: 100%;
}
@media (min-width: 1000px) {
  .navegacion a:not(.admin, .cerrar-sesion) {
    margin-right: 2rem;
    font-size: 1.8rem;
  }
  .navegacion a:not(.admin, .cerrar-sesion):last-of-type {
    margin-right: 0;
  }
}

/* ------------------ Estilos Específicos para Admin/Cerrar Sesión ----------------- */
.admin, .cerrar-sesion {
  padding: 0.1rem 1rem;
  border-radius: 1.5rem;
  transition: all 0.3s ease;
  display: block;
  font-family: "Roboto Flex", sans-serif;
  font-variation-settings: "wght" 300;
  text-align: center;
  font-weight: 300;
  letter-spacing: 0px;
  color: #FFFFFF;
  cursor: pointer;
}
.admin:hover, .cerrar-sesion:hover {
  color: #FFFFFF;
  font-variation-settings: "wght" 500;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.admin {
  background-color: rgba(0, 176, 176, 0.4);
  margin-right: 0;
}
@media (min-width: 1000px) {
  .admin {
    margin-right: 1rem;
  }
}
.admin:hover {
  background-color: rgba(0, 176, 176, 0.7);
}

.cerrar-sesion {
  background-color: rgba(255, 0, 0, 0.4);
}
.cerrar-sesion:hover {
  background-color: rgba(255, 0, 0, 0.6);
}

/* ------------------ Menú Hamburguesa ----------------- */
@media (min-width: 1000px) {
  .mobile-menu {
    display: none;
  }
}
.mobile-menu img {
  width: 5rem;
  cursor: pointer;
}

.mostrar {
  visibility: visible;
  opacity: 1;
  height: auto;
}

.fade-out {
  opacity: 0;
}

/* ------------------ Barra Superior ----------------- */
.barra {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
}
@media (min-width: 1000px) {
  .barra {
    flex-direction: row;
    justify-content: space-between;
  }
}
.barra a img {
  margin-bottom: 0.5rem;
  width: auto;
  height: 100px;
}
@media (min-width: 1000px) {
  .barra a img {
    margin-bottom: 0;
    padding-bottom: 0.1rem;
  }
}

/* ------------------ Logo y Título ----------------- */
.logo-titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min-content;
}
@media (min-width: 1000px) {
  .logo-titulo {
    flex-direction: row;
    gap: 2rem;
  }
}
.logo-titulo h1 {
  font-weight: 300;
  line-height: 4rem;
  margin: 0.5rem;
}
@media (min-width: 1000px) {
  .logo-titulo h1 {
    text-align: center;
  }
}
.logo-titulo h1 span {
  font-weight: 700;
}

/* ------------------ Dark Mode Button ----------------- */
.dark-mode-button {
  margin-left: 3.5rem;
  margin-top: 1rem;
  width: 4rem;
  filter: invert(1);
  border-radius: 4rem;
  padding: 0.3rem;
  transition: all 0.3s;
}
@media (min-width: 1000px) {
  .dark-mode-button {
    margin-left: 1.5rem;
    margin-top: 0;
  }
}
.dark-mode-button:hover {
  cursor: pointer;
  filter: invert(0);
  background-color: rgba(255, 255, 255, 0.75);
}

@media (min-width: 1000px) {
  .iconos-nosotros {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .iconos-nosotros-main {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
}
.icono-main {
  text-align: center;
}
.icono-main img {
  height: 30rem;
  width: 30rem;
  margin: 0 auto;
}
.icono-main p {
  padding: 0rem 2rem;
  text-align: justify;
}

.icono {
  text-align: center;
}
.icono img {
  height: 10rem;
}
.icono h3 {
  text-transform: uppercase;
}

@media (min-width: 1000px) {
  .contenedor-anuncios {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
.anuncio {
  background-color: rgb(237.75, 237.75, 237.75);
  border: 1px solid rgb(212.25, 212.25, 212.25);
  margin-bottom: 2rem;
}
.anuncio .contenido-anuncio {
  padding: 2rem;
}
.anuncio h3, .anuncio p {
  margin: 0;
}

.precio {
  color: #00b0b0;
  font-weight: 700;
  font-size: 3rem;
}

.iconos-caracteristicas {
  display: flex;
  padding: 0;
  list-style: none;
  max-width: 40rem;
}
.iconos-caracteristicas li {
  flex: 1;
  display: flex;
}
.iconos-caracteristicas li img {
  flex: 0 0 3rem;
  margin-right: 1rem;
}
.iconos-caracteristicas li p {
  font-weight: 900;
}

.resumen-propiedad {
  background-color: #e1e1e1;
  padding: 2rem;
  border-radius: 1rem;
}

.no-webp .imagen-contacto {
  background-image: url("../img/encuentra.jpg");
}

.webp .imagen-contacto {
  background-image: url("../img/encuentra.webp");
}

.imagen-contacto {
  background-position: center center;
  background-size: cover;
  padding: 10rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imagen-contacto h2 {
  font-size: 4rem;
  font-weight: 900;
  color: #FFFFFF;
}
.imagen-contacto p {
  font-size: 1.8rem;
  color: #FFFFFF;
}

@media (min-width: 1000px) {
  .seccion-inferior {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 2rem;
  }
}
.entrada-blog {
  margin-bottom: 2rem;
}
@media (min-width: 1000px) {
  .entrada-blog {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 2rem;
  }
}
.entrada-blog:last-of-type {
  margin-bottom: 0;
}

.informacion-meta span {
  color: rgba(11, 218, 241, 0.6705882353);
}

.texto-entrada a {
  color: #000000;
  margin-top: 2rem;
  display: block;
}
@media (min-width: 1000px) {
  .texto-entrada a {
    margin-top: 0;
  }
}
.texto-entrada h4 {
  margin: 0;
  line-height: 1.4;
}
.texto-entrada h4::after {
  content: "";
  display: block;
  margin-top: 1rem;
  width: 50%;
  height: 0.4rem;
  background-color: #00b0b0;
  transition: all 0.3s;
}
.texto-entrada h4:hover::after {
  width: 100%;
}

.testimonial {
  background-color: #00b0b0;
  font-size: 2.4rem;
  padding: 2rem;
  color: #FFFFFF;
  border-radius: 2rem;
}
.testimonial blockquote {
  padding-left: 5rem;
  position: relative;
}
.testimonial blockquote::before {
  content: "";
  background-image: url("../img/comilla.svg");
  background-repeat: no-repeat;
  width: 6rem;
  height: 8rem;
  display: block;
  position: absolute;
  left: -2rem;
}
.testimonial p {
  text-align: right;
  color: #FFFFFF;
}
@media (min-width: 1000px) {
  .testimonial p {
    padding-right: 4rem;
  }
}

.formulario p {
  font-size: 1.4rem;
  color: #333333;
  margin: 2rem 0 0 0;
}
.formulario legend {
  font-size: 2rem;
  color: #333333;
}
.formulario label {
  font-weight: 700;
  text-transform: uppercase;
  display: block;
}
.formulario h4 {
  margin: 0.5rem;
  font-size: 2rem;
}
.formulario input:not([type=submit]), .formulario textarea, .formulario select {
  padding: 1rem;
  display: block;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #e1e1e1;
  border-radius: 1rem;
  margin-bottom: 2rem;
}
.formulario .textarea {
  height: 20rem;
}
.formulario .forma-contacto {
  max-width: 30rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formulario input[type=radio] {
  margin: 0;
  width: auto;
}

.preview-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 10px;
}
.preview-container img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/** Páginas Internas **/
@media (min-width: 1000px) {
  .contenido-nosotros {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
}
.contenido-nosotros h4 {
  margin: 3rem 0 0 0;
}

.alerta {
  padding: 0.5rem;
  text-align: center;
  color: #FFFFFF;
  text-transform: uppercase;
  margin: 1rem 0;
  border-radius: 0.5rem;
  transition: all 0.5s ease-out;
}
.alerta.error {
  background-color: rgba(255, 0, 0, 0.662);
}
.alerta.exito {
  background-color: #00b0b0;
}

table.propiedades {
  margin-top: 3rem;
  width: 100%;
  border-spacing: 0;
}
table.propiedades thead {
  background-color: #00b0b0;
}
table.propiedades thead th {
  color: #FFFFFF;
  padding: 1rem;
}
table.propiedades .imagen-tabla {
  width: 15rem;
  margin: 0 auto;
}
table.propiedades tbody tr td {
  border-bottom: 2px;
  border-color: #e1e1e1;
  border-bottom-style: solid;
  text-align: center;
}

.imagen-small {
  width: 30rem;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal-contenido {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}