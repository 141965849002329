#whatsapp-button {
    position: fixed;
    bottom: 10rem;
    right: 2rem;
    z-index: 1001;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    overflow: hidden; // Evita que el fondo animado se salga
    border: 3px solid #25D366; // Borde verde de WhatsApp
    cursor: pointer;
    transition: all 0.3s ease;
  
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: relative;
        font-size: 1.2rem;
        font-weight: bold;
        color: white;
        background: none;
    }

    // Animación de efecto líquido
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(#25D366 0 0) 
            no-repeat calc(200% - var(--p, 0%)) 100% / 200% var(--p, 0.2em);
        transition: 0.3s var(--t, 0s), background-position 0.3s calc(0.3s - var(--t, 0s));
        z-index: -1;
    }

    &:hover {
        --p: 100%;
        --t: 0.3s;
        color: white;
    }

    svg {
        width: 60%;
        height: 60%;
        fill: rgb(9, 184, 9); 
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 1));
        transition: transform 0.3s ease-in-out;
        stroke: rgb(9, 184, 9); // Mismo color que el fill
        stroke-width: .3px; // Grosor del contorno
        &:hover {
            transform: scale(1.1);
        }
    }
}
