@use '../base/variables' as *;
@use '../base/mixins' as *;

.boton-amarillo {
    @include boton($turquesaOscuro, inline-block);
}

.boton-amarillo-block {
    @include boton($turquesaClaro, block);
}

.boton-turquesa {
    @include boton2($turquesaClaro);
}

.boton-verde {
    @include boton($turquesa, inline-block);
}

.boton-rojo-block {
    @include boton($rojo, block);
    width: 100%;
}

.boton-verde-block {
    @include boton($verde, block);
}