@use '../base/mixins' as *;
@use '../base/variables' as *;

@include tablet {
    .seccion-inferior {
        display: grid;
        grid-template-columns: 2fr 1fr;
        column-gap: 2rem;
    }
}

.entrada-blog {
    margin-bottom: 2rem;

    @include tablet {
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap: 2rem;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
}

.informacion-meta {
    span {
    color: $amarillo;
    }
}

.texto-entrada {

    a{
        color: $negro;
        margin-top: 2rem;
        display: block; //los enlaces por defecto usan display inline, lo que no generaría el margin-top, por eso se establece el block
        @include tablet {
            margin-top: 0;
        }
    }
    h4 {
        margin: 0;
        line-height: 1.4;

        &::after {
            content: '';
            display: block;
            margin-top: 1rem;
            width: 50%;
            height: .4rem;
            background-color: $verde;
            transition: all .3s;
        }

        &:hover::after {
            width: 100%;
        }
    }
}