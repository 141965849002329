@use '../base/variables' as *;
@use '../base/mixins' as *;

.alerta {
    padding: .5rem;
    text-align: center;
    color: $blanco;
    text-transform: uppercase;
    margin: 1rem 0;
    border-radius: .5rem;
    transition: all .5s ease-out;
    &.error {
        background-color: $rojo;
    }
    &.exito {
        background-color: $verde;
    }
}

table.propiedades {
    margin-top: 3rem;
    width: 100%;
    border-spacing: 0;

    thead {
        background-color: $verde;

        th {
            color: $blanco;
            padding: 1rem;
        }
    }

    .imagen-tabla {
        width: 15rem;
        margin: 0 auto;
    }

    tbody {
        tr {
            td {
                border-bottom: 2px;
                border-color: $gris;
                border-bottom-style: solid;
                text-align: center;
            } 
        } 
    }
}

.imagen-small {
    width: 30rem;
}

.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}

.modal-contenido {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}