@use '../base/variables.scss' as *;

.imagen-container {
    position: relative;
    display: inline-block;

    .btn-eliminar-imagen {
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: $rojoClaro;
        transition: box-shadow .2s ease-in;
        color: #fff;
        border: 1px solid $grisOscuro;
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
        display: none;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        line-height: 1;
            &:hover {
                box-shadow: 0 0 .3rem .4rem rgba($color: $amarillo, $alpha: .7);
            }
    }

    &:hover .btn-eliminar-imagen {
        display: flex;
    }
}