@use '../base/variables' as *;
@use '../base/mixins' as *;

// Contenedor principal del swiper
swiper-container {
    width: 100%;
    height: 100%;
  }

  swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  swiper-container {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  swiper-slide {
    background-size: cover;
    background-position: center;
    border-radius: 2rem;
  }

  .mySwiper {
    height: 80%;
    width: 100%;
    padding: 1rem 3rem;
  }

  @include tablet {
    .mySwiper {
        padding: 1rem 30rem;
    }
  }

  .mySwiper2 {
    height: 20%;
    box-sizing: border-box;
    padding: 1rem 3rem;
  }

  @include tablet {
    .mySwiper2 {
        padding: 1rem 30rem;
    }
  }

  .mySwiper2 swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .mySwiper2 .swiper-slide-thumb-active {
    opacity: 1;
  }

  swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2rem;
  }
