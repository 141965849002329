@use '../base/mixins' as *;
@use '../base/variables' as *;

.formulario {
    p {
        font-size: 1.4rem;
        color: $grisOscuro;
        margin: 2rem 0 0 0;
    }

    legend {
        font-size: 2rem;
        color: $grisOscuro;
    }

    label {
        font-weight: $bold;
        text-transform: uppercase;
        display: block;
    }
    
    h4 {
        margin: .5rem;
        font-size: 2rem;
    }

    // input[type="text"] { //selector de atributo, genera muchas líneas de código pero es válido para seleccionar uno
    
    input:not([type="submit"]), textarea, select {
        padding: 1rem;
        display: block;
        width: 100%;
        background-color: $blanco;
        border: 1px solid $gris;
        border-radius: 1rem;
        margin-bottom: 2rem;
    }

    .textarea {
        height: 20rem;
    }

    .forma-contacto {
        max-width: 30rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    input[type="radio"] {
        margin: 0;
        width: auto;
    }
}

.preview-container {
    display: flex;
    flex-wrap: wrap; // Permite que las imágenes pasen a otra línea si no caben
    justify-content: flex-start; // Alinea a la derecha, pero respeta el espacio disponible
    gap: 10px; // Espacio entre imágenes
    margin-top: 10px;

    img {
        width: 150px;
        height: 150px;
        object-fit: cover; // Evita que se deformen
        border-radius: .5rem;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }
}

.tipo-propiedad {
    display: flex;
    border-radius: 1rem;
    border: 1px solid $gris;
    gap: 2rem;
    width: auto;

    legend {
        font-size: 1.8rem;
    }

    .radio {
        display: flex;
        gap: .5rem;
    }
}