@use '../base/variables' as *;

.no-webp .imagen-contacto {
    background-image: url('../img/encuentra.jpg');
}

.webp .imagen-contacto {
    background-image: url('../img/encuentra.webp');
}

.imagen-contacto {
    background-position: center center;
    background-size: cover;
    padding: $separacion *2 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-size: 4rem;
        font-weight: $black;
        color: $blanco;
        padding: 0 1rem 0 1rem;
    }
    p {
        font-size: 1.8rem;
        color: $blanco;
        padding: 0 1rem 0 1rem;
        text-align: center;
    }
}