@use '../base/variables' as *;
@use 'sass:color';

.dark-mode {
    background-color: color.adjust($grisOscuro, $lightness: -10%);
    transition: all .3s;

    b {
        color: $blanco;
    }
    
    a, h1, h3, h4, p {
        color: $blanco;
    }

    a, h1, h2, h3, h4 {
        color: $gris;
    }

    .anuncio, .resumen-propiedad, .resumen-entrada {
        background-color: $grisOscuro;
        border: 1px solid $grisOscuro;

        .precio {
            color: $verde;
        }
        .icono {
            filter: invert(1);
        }
    }

    .resumen-entrada, .resumen-propiedad {
        padding: 2rem;
        border-radius: 1rem;
    }

    .formulario {
        legend, label, p {
            color: $gris;
        }

        input:not([type="submit"]),
        textarea, select {
            background-color: $grisOscuro;
            border: 1px solid $grisOscuro;
            color: $blanco;
        }
    }

    table.propiedades {
        tbody {
            tr {
                td {
                    color: $blanco;
                    border-color: $grisOscuro;
                }
            }
        }
    }

    .modal {
        .modal-contenido {
            h2 {
                font-weight: bold;
            }
            h2, p {
                color: $grisOscuro;
            }
        }
    }
    .contenido-centrado {
        h1 {
            font-weight: 600;
        }
    }
    /* Estilos para el modo oscuro de SweetAlert2 */
    .swal2-dark {
        background: #1e1e1e; // Fondo oscuro
        color: #ffffff; // Texto blanco
    
        .swal2-title, .swal2-html-container {
            color: #ffffff; // Texto blanco para título y contenido
            font-size: 2.5rem; // Tamaño de fuente para título
            background-color: #1e1e1e; // Fondo oscuro para el contenido
        }
    
        .swal2-html-container {
            color: #cccccc; // Texto gris claro para el contenido
            font-size: 1.5rem; // Tamaño de fuente para el contenido
        }
    
        .swal2-success-circular-line-left,
        .swal2-success-circular-line-right,
        .swal2-success-fix {
            background-color: transparent !important; // Fondo transparente
        }
    
        .swal2-success {
            color: #00ff00; // Color del icono de éxito (verde)
            border-color: #00ff00; // Color del borde del icono de éxito
            background-color: transparent; // Fondo transparente
        }
    
        .swal2-confirm {
            background-color: #00ff00; // Fondo verde para el botón de confirmación
            color: #1e1e1e; // Texto oscuro para el botón
            border: none; // Elimina el borde del botón
            font-size: 1.5rem; // Tamaño de fuente para el botón
    
            &:hover {
                background-color: #00cc00; // Fondo verde más oscuro al pasar el mouse
            }
        }
    }
}