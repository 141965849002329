@use '../base/variables' as *;
@use '../base/mixins' as *;

.boton-amarillo {
    @include boton($amarillo, inline-block);
}

.boton-amarillo-block {
    @include boton($amarillo, block);
}

.boton-turquesa {
    @include boton2($amarillo);
}

.boton-verde {
    @include boton($verde, inline-block);
}

.boton-rojo-block {
    @include boton($rojo, block);
    width: 100%;
}

.boton-verde-block {
    @include boton($verde, block);
}