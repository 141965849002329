@use '../base/variables' as *;
@use '../base/mixins' as *;

.contenido-nosotros {
    @include tablet {
        @include grid(2, 2rem);
        
        
    }
    h4 {
        margin: 3rem 0 0 0;
    }
}

