@use '../base/mixins' as *;
@use '../base/variables' as *;

@include tablet {
    .iconos-nosotros {
        @include grid(3, 2rem);
    }
    .iconos-nosotros-main {
        @include grid(2, 2rem);
    }
}

.icono-main {
    text-align: center;
    img {
        height: 30rem;
        width: 30rem;
        margin: 0 auto;
    }
    p {
        padding: 0rem 2rem;
        text-align: justify;
    }
}

.icono {
    text-align: center;
    img {
        height: 10rem;
    }
    h3 {
        text-transform: uppercase;
    }
}