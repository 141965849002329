@use '../base/mixins' as *;
@use '../base/variables' as *;
@use 'sass:color';
@use '../base/fonts' as *;

/* ------------------ Estilos Generales de Navegación ----------------- */
.navegacion {
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out, opacity .3s linear, height .3s linear;

    @include tablet {
        display: flex;
        align-items: center;
        opacity: 1;
        visibility: visible;
    }

    a:not(.admin, .cerrar-sesion) {
        color: $blanco;
        text-decoration: none;
        font-size: 2.2rem;
        padding-bottom: .3rem;
        position: relative;
        display: block;
        text-align: center;
        transition: all .3s ease-in-out;

        &:hover {
            color: $verde;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 2px;
            background-color: $verde;
            transition: width 0.3s ease-in-out;
        }

        &:hover::after {
            width: 100%;
        }

        @include tablet {
            margin-right: 2rem;
            font-size: 1.8rem;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}

/* ------------------ Estilos Específicos para Admin/Cerrar Sesión ----------------- */
.admin, .cerrar-sesion {
    padding: .1rem 1rem;
    border-radius: 1.5rem;
    transition: all .3s ease;
    display: block;
    font-family: 'Roboto Flex', sans-serif;
    font-variation-settings: 'wght' 300;
    text-align: center;
    font-weight: 300;
    letter-spacing: 0px;
    color: $blanco;
    cursor: pointer;

    &:hover {
        color: $blanco;
        font-variation-settings: 'wght' 500;
        font-weight: 500;
        letter-spacing: .5px;
    }
}

.admin {
    background-color: rgba($verde, .4);
    margin-right: 0;

    @include tablet {
        margin-right: 1rem;
    }
    
    &:hover {
        background-color: rgba($verde, .7);
    }
}

.cerrar-sesion {
    background-color: rgba($rojo, .4);
    
    &:hover {
        background-color: rgba($rojo, .6);
    }
}

/* ------------------ Menú Hamburguesa ----------------- */
.mobile-menu {
    @include tablet {
        display: none;
    }

    img {
        width: 5rem;
        cursor: pointer;
    }
}

.mostrar {
    visibility: visible;
    opacity: 1;
    height: auto;
}

.fade-out {
    opacity: 0;
}

/* ------------------ Barra Superior ----------------- */
.barra {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;

    @include tablet {
        flex-direction: row;
        justify-content: space-between;
    }

    a img {
        margin-bottom: .5rem;
        width: auto;
        height: 100px;

        @include tablet {
            margin-bottom: 0;
            padding-bottom: .1rem;
        }
    }
}

/* ------------------ Logo y Título ----------------- */
.logo-titulo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: min-content;

    @include tablet {
        flex-direction: row;
        gap: 2rem;
    }

    h1 {
        font-weight: 300;
        line-height: 4rem;
        margin: .5rem;

        @include tablet {
            text-align: center;
        }

        span {
            font-weight: 700;
        }
    }
}

/* ------------------ Dark Mode Button ----------------- */
.dark-mode-button {
    margin-left: 3.5rem;
    margin-top: 1rem;
    width: 4rem;
    filter: invert(1);
    border-radius: 4rem;
    padding: .3rem;
    transition: all .3s;

    @include tablet {
        margin-left: 1.5rem;
        margin-top: 0;
    }

    &:hover {
        cursor: pointer;
        filter: invert(0);
        background-color: color.mix($blanco, transparent, 75%);
    }
}