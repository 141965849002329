@use '../base/variables.scss' as *;
@use 'sass:math';
html {
    font-size: 62.5%;
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}
body {
    font-family: $fuente_principal;
    font-size: 1.6rem;
    line-height: 1.8;
    transition: all .3s;
}
p {
    color: $negro;
    font-size: 2rem;
}
.contenedor {
    width: 95%;
    max-width: 120rem;
    margin: 0 auto;
    h2 {
        font-weight: 800;
    }
}
.contenido-centrado {
    h1 {
        font-weight: 600;
    }
}

a {
    text-decoration: none;
}

img,
picture {
    width: 100%;
    display: block;
}

h1, h2, h3 {
    font-family: $fuente_principal;
    margin: math.div($separacion, 2) 0;
    font-weight: 300;
    text-align: center;
    
}

h1 {
    font-size: 3.8rem;
}
h2 {
    font-size: 3.4rem;
}
h3 {
    font-size: 3rem;
}
h4 {
    font-size: 2.6rem;
}
.swal2-title, .swal2-html-container {
    font-size: 2.5rem; // Tamaño de fuente para título
}

.swal2-html-container {
    font-size: 1.5rem; // Tamaño de fuente para el contenido
}

.swal2-success-circular-line-left,
.swal2-success-circular-line-right,
.swal2-success-fix {
    background-color: transparent !important; // Fondo transparente
}

.swal2-success {
    color: #00ff00; // Color del icono de éxito (verde)
    border-color: #00ff00; // Color del borde del icono de éxito
    background-color: transparent; // Fondo transparente
}

.swal2-confirm {
    background-color: #00ff00; // Fondo verde para el botón de confirmación
    color: #1e1e1e; // Texto oscuro para el botón
    border: none; // Elimina el borde del botón
    font-size: 1.5rem; // Tamaño de fuente para el botón

    &:hover {
        background-color: #00cc00; // Fondo verde más oscuro al pasar el mouse
    }
}

// .preview-container {
//     display: flex;
//     flex-wrap: wrap;
//     gap: 1rem;
//   }
  
.imagen-container {
    position: relative;
    display: inline-block;

        .btn-eliminar-imagen {
            position: absolute;
            top: 5px;
            right: 5px;
            background-color: $rojoClaro;
            transition: box-shadow .2s ease-in;
            color: #fff;
            border: 1px solid $grisOscuro;
            border-radius: 50%;
            width: 2.5rem;
            height: 2.5rem;
            cursor: pointer;
            display: none;
            align-items: center;
            justify-content: center;
            font-size: 1.2rem;
            line-height: 1;
            &:hover {
                box-shadow: 0 0 .3rem .4rem rgba($color: $amarillo, $alpha: .7);
            }
        }

        &:hover .btn-eliminar-imagen {
            display: flex;
        }
}